/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Layout } from '@leshen/gatsby-theme-contentful'
import Helmet from 'react-helmet'

import {
  Accordion,
  AvailabilityForm,
  Billboard,
  SplitContent,
  VariableContent,
  PackageCard,
  Image,
  useMapi,
  SVGImage,
} from '@leshen/gatsby-theme-leshen'

import {
  Stack,
  Columns,
  Column,
  Typography,
  Brandy,
  Dropdown,
} from '@leshen/ui'
import { graphql, Link as GatsbyLink, navigate } from 'gatsby'
import reactStringReplace from 'react-string-replace'

import SeoCtaBar from '../components/SeoCtaBar'
import getFullStateName from '../utils/getFullStateName'
import siteTheme from '../../theme'

const StateTemplate = ({ data }) => {
  const {
    Page_Path,
    Promo_Code,
    Static_Number,
    State,
    Hero_Copy,
    Hero_Disclaimer,
    Cards_Copy,
    Zip_Code_Disclaimer,
    Package_Cards_Copy,
    City_Dropdown_Copy,
    City_Dropdown_Disclaimer,
    FAQ_1,
    FAQ_2,
    FAQ_3,
    FAQ_4,
  } = data.pageInfo.edges[0].node

  const State_Name = getFullStateName(State)

  const { rotatedNumber } = useMapi()

  const brandyPackageList = ['ctlb-internetlowtier', 'ctlb-internetfiber']

  const packageList = brandyPackageList
    .map((packageId) =>
      data?.allContentfulPackage?.edges?.find(
        ({ node }) => node.identifier === packageId
      )
    )
    .filter((node) => node)

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: `Find CenturyLink Business internet, phone, bundles or fiber services in ${State_Name} | Call ${Static_Number}.`,
        robots: 'index,follow',
        title: `Business Internet Plans in ${State_Name} | ${Static_Number} - CenturyLink®`,
        titleTemplate: '',
      },
      path: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: null,
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
    },
  }

  const getFAQ = (shortName) => {
    const faq = {
      QA1: {
        question: `Is CenturyLink Business available in ${State_Name}?`,
        answer: (
          <Typography variant="feature">
            Yes. CenturyLink Business is available in {State_Name}.{' '}
            <GatsbyLink to="/internet">Click here</GatsbyLink> to view internet
            packages and see if service is available in your area.
          </Typography>
        ),
      },
      QA2: {
        question: `Does Centurylink Business offer service in ${State_Name}?`,
        answer: (
          <Typography variant="feature">
            CenturyLink Business does offer service in {State_Name}. To speak to
            one of our customer service representatives and find out if service
            is available in your area, please call{' '}
            <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a>.
          </Typography>
        ),
      },
      QA3: {
        question: `Is CenturyLink Business an internet provider in ${State_Name}?`,
        answer: (
          <Typography variant="feature">
            Yes. CenturyLink Business is an internet provider in {State_Name}.
            To find out if services are available in your area contact us at{' '}
            <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a>.
          </Typography>
        ),
      },
      QA4: {
        question: `Can I get CenturyLink Business fiber internet in ${State_Name}? `,
        answer: (
          <Typography variant="feature">
            CenturyLink Business fiber internet is available in {State_Name}.
            Check to see if services are available in your area. You can check
            our{' '}
            <GatsbyLink to="/internet/small-business-fiber">
              fiber packages
            </GatsbyLink>{' '}
            and call to <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a>
          </Typography>
        ),
      },
      QP1: {
        question: `How much is CenturyLink Business internet in ${State_Name}?`,
        answer: (
          <Typography variant="feature">
            Internet service starts at $30/mo., but CenturyLink Business
            internet prices can vary based on the package you choose. Speed may
            not be available in your area. Paperless billing required. Taxes and
            fees apply.
          </Typography>
        ),
      },
      QP2: {
        question: `How much do CenturyLink Business packages cost in ${State_Name}? `,
        answer: (
          <Typography variant="feature">
            CenturyLink Business packages vary in cost depending on the package
            you choose and start at $30/mo. Speed may not be available in your
            area. Paperless billing required. Taxes and fees apply.
          </Typography>
        ),
      },
      QP3: {
        question: `What is the price of CenturyLink Business internet in ${State_Name}?`,
        answer: (
          <Typography variant="feature">
            CenturyLink Business fiber internet starts at $30/mo. in{' '}
            {State_Name}. Speed may not be available in your area. Paperless
            billing required. Taxes and fees apply.
          </Typography>
        ),
      },
      QP4: {
        question: `How much do CenturyLink Business internet and phone packages in ${State_Name} cost?`,
        answer: (
          <Typography variant="feature">
            CenturyLink Business internet starts at $30/mo. However, the price
            of bundling a phone service with your internet package will vary
            depending on the exact services you choose. Speed may not be
            available in your area. Paperless billing required. Taxes and fees
            apply.
          </Typography>
        ),
      },
      QC1: {
        question: `What is the number to reach CenturyLink Business in ${State_Name}?`,
        answer: (
          <Typography variant="feature">
            For now, you can reach CenturyLink Business in {State_Name} by
            calling CenturyLink customer service at{' '}
            <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a>.
          </Typography>
        ),
      },
      QC2: {
        question: `What is the number for CenturyLink Business in ${State_Name} to add a service?`,
        answer: (
          <Typography variant="feature">
            The phone number to call in order to add a CenturyLink Business
            service is <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a>.
          </Typography>
        ),
      },
      QC3: {
        question: `What is the moving phone number for CenturyLink Business in ${State_Name}?`,
        answer: (
          <Typography variant="feature">
            The moving phone number for CenturyLink Business in {State_Name} is{' '}
            <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a>.
          </Typography>
        ),
      },
      QC4: {
        question: `How do I contact CenturyLink Business in ${State_Name}?`,
        answer: (
          <Typography variant="feature">
            You can contact CenturyLink Business in {State_Name} by calling{' '}
            <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a> or chat with a
            member of our{' '}
            <GatsbyLink to="/business-support">
              customer support team
            </GatsbyLink>{' '}
            on our website.
          </Typography>
        ),
      },
      QB1: {
        question: `Does CenturyLink Business in ${State_Name} offer bundles with internet? `,
        answer: (
          <Typography variant="feature">
            Yes. Our internet services can come bundled in {State_Name}.{' '}
            <GatsbyLink to="/bundles">Click here</GatsbyLink> to view bundle
            packages!
          </Typography>
        ),
      },
      QB2: {
        question: `Can I get home phone with CenturyLink Business fiber internet in ${State_Name}?`,
        answer: (
          <Typography variant="feature">
            A home phone line can be added to your CenturyLink Business{' '}
            <GatsbyLink to="/internet/small-business-fiber">
              fiber internet package
            </GatsbyLink>{' '}
            in {State_Name}. Check to see if service is available in your area.
          </Typography>
        ),
      },
      QB3: {
        question: `Does CenturyLink Business offer internet and home phone bundles?`,
        answer: (
          <Typography variant="feature">
            Yes. CenturyLink Business offers affordable and reliable internet
            and home phone bundles. Check to see if service is available in your
            area and click to view our{' '}
            <GatsbyLink to="/bundles">bundle packages</GatsbyLink>!
          </Typography>
        ),
      },
      QB4: {
        question: `Does CenturyLink Business offer bundles in ${State_Name}?`,
        answer: (
          <Typography variant="feature">
            CenturyLink Business offers a variety of bundles for both home and
            business usage in {State_Name}. Check to see if service is available
            in your area and click to view our{' '}
            <GatsbyLink to="/bundles">bundle packages</GatsbyLink>!
          </Typography>
        ),
      },
      ALL1: {
        question: `Does CenturyLink Business offer TV and internet bundles in ${State_Name}?`,
        answer: (
          <Typography variant="feature">
            Currently, CenturyLink Business does not offer TV service.
          </Typography>
        ),
      },
      ALL2: {
        question: `Are Brightspeed and Centurylink in ${State_Name} the same company?`,
        answer: (
          <Typography variant="feature">
            No. Brightspeed and CenturyLink are not the same company.
          </Typography>
        ),
      },
    }
    return faq[shortName]
  }

  const faqSchemaData = (shortName, piece) => {
    const schemaFAQ = {
      QA1: {
        question: `Is CenturyLink Business available in ${State_Name}?`,
        answer: `Yes. CenturyLink Business is available in ${State_Name}. Click here to view internet packages! Services not available everywhere. Restrictions apply.`,
      },
      QA2: {
        question: `Does Centurylink Business offer service in ${State_Name}?`,
        answer: `CenturyLink Business does offer service in ${State_Name}. To speak to one of our customer service representatives and find out if service is available in your area, please call <a href='tel:${rotatedNumber}'>${rotatedNumber}</a>.`,
      },
      QA3: {
        question: `Is CenturyLink Business an internet provider in ${State_Name}?`,
        answer: `Yes. CenturyLink Business is an internet provider in ${State_Name}. To find out if services are available in your area, please contact us at <a href='tel:${rotatedNumber}'>${rotatedNumber}</a>.`,
      },
      QA4: {
        question: `Can I get CenturyLink Business fiber internet in ${State_Name}?`,
        answer: `CenturyLink Business fiber internet is available in ${State_Name}. Check to see if services are available in your area. You can check our fiber packages and call us at <a href='tel:${rotatedNumber}'>${rotatedNumber}</a>.`,
      },
      QP1: {
        question: `How much is CenturyLink Business internet in ${State_Name}?`,
        answer:
          'Internet service starts at $30/mo., but CenturyLink Business internet prices can vary based on the package you choose. Speed may not be available in your area. Paperless billing required. Taxes and fees apply.',
      },
      QP2: {
        question: `How much do CenturyLink Business packages cost in ${State_Name}?`,
        answer:
          'CenturyLink Business packages vary in cost depending on the package you choose and start at $30/mo. Speed may not be available in your area. Paperless billing required. Taxes and fees apply.',
      },
      QP3: {
        question: `What is the price of CenturyLink Business internet in ${State_Name}?`,
        answer: `CenturyLink Business fiber internet starts at $30/mo. in ${State_Name}. Speed may not be available in your area. Paperless billing required. Taxes and fees apply.`,
      },
      QP4: {
        question: `How much do CenturyLink Business internet and phone packages in ${State_Name} cost?`,
        answer: `CenturyLink Business internet starts at $30/mo. However, the price of bundling a phone service with your internet package will vary depending on the exact services you choose. Speed may not be available in your area. Paperless billing required. Taxes and fees apply.`,
      },
      QC1: {
        question: `What is the number to reach CenturyLink Business in ${State_Name}?`,
        answer: `For now, you can reach CenturyLink Business in ${State_Name} by calling CenturyLink customer service at <a href='tel:${rotatedNumber}'>${rotatedNumber}</a>.`,
      },
      QC2: {
        question: `What is the number for CenturyLink Business in ${State_Name} to add a service?`,
        answer: `The phone number to call in order to add a CenturyLink Business service is <a href='tel:${rotatedNumber}'>${rotatedNumber}</a>.`,
      },
      QC3: {
        question: `What is the moving phone number for CenturyLink Business in ${State_Name}?`,
        answer: `The moving phone number for CenturyLink Business in ${State_Name} is <a href='tel:${rotatedNumber}'>${rotatedNumber}</a>`,
      },
      QC4: {
        question: `How do I contact CenturyLink Business in ${State_Name}?`,
        answer: `You can contact CenturyLink Business in ${State_Name} by calling <a href='tel:${rotatedNumber}'>${rotatedNumber}</a> or chat with a member of our customer support team on our website.`,
      },
      QB1: {
        question: `Does CenturyLink Business in ${State_Name} offer bundles with internet?`,
        answer: `Yes. Our internet services can come bundled in ${State_Name}. Click here to view bundle packages!`,
      },
      QB2: {
        question: `Can I get home phone with CenturyLink Business fiber internet in ${State_Name}?`,
        answer: `A home phone line can be added to your CenturyLink Business fiber internet package in ${State_Name}. Check to see if service is available in your area.`,
      },
      QB3: {
        question: `Does CenturyLink Business offer internet and home phone bundles?`,
        answer: `Yes. CenturyLink Business offers affordable and reliable internet and home phone bundles. Check to see if service is available in your area and click to view our <a href='/bundles'>bundle packages</a>!`,
      },
      QB4: {
        question: `Does CenturyLink Business offer bundles in ${State_Name}?`,
        answer: `CenturyLink Business offers a variety of bundles for both home and business usage in ${State_Name}. Check to see if service is available in your area and click to view our <a href='/bundles'>bundle packages</a>!`,
      },
      ALL1: {
        question: `Does CenturyLink Business offer TV and internet bundles in ${State_Name}?`,
        answer: `Currently, CenturyLink Business does not offer TV service.`,
      },
      ALL2: {
        question: `Are Brightspeed and Centurylink in ${State_Name} the same company?`,
        answer: `No. Brightspeed and CenturyLink are not the same company.`,
      },
    }
    return schemaFAQ?.[shortName]?.[piece]
  }

  const generateSchema = () => {
    const schema = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "${faqSchemaData(FAQ_1, 'question')}",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "${faqSchemaData(FAQ_1, 'answer')}"
            }
          },
          {
            "@type": "Question",
            "name": "${faqSchemaData(FAQ_2, 'question')}",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "${faqSchemaData(FAQ_2, 'answer')}"
            }
          },
          {
            "@type": "Question",
            "name": "${faqSchemaData(FAQ_3, 'question')}",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "${faqSchemaData(FAQ_3, 'answer')}"
            }
          },
          {
            "@type": "Question",
            "name": "${faqSchemaData(FAQ_4, 'question')}",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "${faqSchemaData(FAQ_4, 'answer')}"
            }
          },
          {
            "@type": "Question",
            "name": "${faqSchemaData('ALL1', 'question')}",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "${faqSchemaData('ALL1', 'answer')}"
            }
          },
          {
            "@type": "Question",
            "name": "${faqSchemaData('ALL2', 'question')}",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "${faqSchemaData('ALL2', 'answer')}"
            }
          }
        ]
      }
      `
    return schema
  }

  const getBundlesLink = (text) => {
    let returnValue = text

    if (text.includes('bundled services')) {
      returnValue = reactStringReplace(text, 'bundled services', (match) => (
        <GatsbyLink to="/bundles">{match}</GatsbyLink>
      ))
    } else if (text.includes('bundles to fit your every need')) {
      returnValue = reactStringReplace(
        text,
        'bundles to fit your every need',
        (match) => <GatsbyLink to="/bundles">{match}</GatsbyLink>
      )
    }

    return returnValue
  }

  const getInternetLink = (text) =>
    reactStringReplace(
      text,
      'CenturyLink Business Internet services',
      (match) => <GatsbyLink to="/internet">{match}</GatsbyLink>
    )

  const cityDropdownData = data.cities.edges.map((current) => ({
    label: current.node.City,
    value: current.node.Page_Path,
  }))

  const stateHeroData = {
    ...data.stateHero?.cloudinary[0]?.gatsbyImageData,
    context: data.stateHero?.cloudinary[0]?.context,
  }
  const stateFlagData = {
    ...data.stateFlag?.cloudinary[0]?.gatsbyImageData,
    context: data.stateFlag?.cloudinary[0]?.context,
  }

  return (
    <Layout
      data={passedData}
      main={
        <>
          <Helmet>
            <script type="application/ld+json">{`${generateSchema()}`}</script>
          </Helmet>
          <Billboard
            customLink={GatsbyLink}
            mainContent={
              <>
                <Typography variant="h1">
                  CenturyLink Business Availability in {State_Name}
                </Typography>
                <Typography>{Hero_Copy}</Typography>
                {Hero_Disclaimer && <Brandy text={Hero_Disclaimer} />}
              </>
            }
            image={<Image data={stateHeroData} isImageCritical />}
          />
          <SeoCtaBar />
          <VariableContent
            style={{ backgroundColor: siteTheme.colors.light }}
            mainContent={
              <>
                <Typography variant="h2">
                  CenturyLink Business Internet & Voice in {State_Name}
                </Typography>
                <Typography> {getBundlesLink(Cards_Copy)}</Typography>
              </>
            }
            alignMainContent="center"
          >
            <Stack spacing="xxl">
              <Columns cardStyled>
                <Column>
                  <SVGImage
                    svg={data?.cardsOne?.cloudinary[0].svg.code}
                    presentationWidth={
                      data?.cardsOne?.cloudinary?.[0]?.svg.presentationWidth
                    }
                  />
                  <Typography variant="h4">
                    Internet that fits your needs.
                  </Typography>
                  <Typography>
                    <GatsbyLink to="/internet/speed-test">
                      Test your current internet
                    </GatsbyLink>{' '}
                    speed to find a business internet plan tailored to fit your
                    company’s needs.
                  </Typography>
                </Column>
                <Column>
                  <SVGImage
                    svg={data?.cardsTwo?.cloudinary[0].svg.code}
                    presentationWidth={
                      data?.cardsTwo?.cloudinary?.[0]?.svg.presentationWidth
                    }
                  />
                  <Typography variant="h4">
                    A connection you can count on.
                  </Typography>
                  <Typography>
                    Keep business running smoothly with a reliable connection,
                    stable speeds, and 24/7{' '}
                    <GatsbyLink to="/business-support">
                      customer support.
                    </GatsbyLink>
                  </Typography>
                </Column>
              </Columns>
            </Stack>
          </VariableContent>
          <SplitContent
            customLink={GatsbyLink}
            image={<Image data={stateFlagData} isImageCritical />}
            mainContent={
              <>
                <Typography variant="h2">
                  Enter your zip code below to find business internet in{' '}
                  {State_Name}
                </Typography>
                <AvailabilityForm
                  label="Zip Code"
                  buttonText="Submit"
                  placeholderText="Zip Code"
                  buttonColor="primary"
                />
                <Brandy text={Zip_Code_Disclaimer} />
              </>
            }
          />{' '}
          <VariableContent
            style={{ backgroundColor: siteTheme.colors.light }}
            mainContent={
              <>
                <Typography variant="h2">
                  CenturyLink Business internet in {State_Name}
                </Typography>
                <Typography>{getInternetLink(Package_Cards_Copy)}</Typography>
              </>
            }
            alignMainContent="center"
          >
            <Stack spacing="xxl">
              <Columns>
                {packageList.map((currentPackage) => (
                  <PackageCard
                    label={currentPackage.node.label}
                    packageData={{ ...currentPackage.node.brandy }}
                    key={currentPackage.node.contentful_id}
                  />
                ))}
              </Columns>
            </Stack>
          </VariableContent>
          <VariableContent
            style={{
              backgroundColor: siteTheme.colors.primary,
              paddingTop: 'calc(0vw + 48px)',
              paddingBottom: 'calc(0vw + 48px)',
            }}
            mainContent={
              <>
                <Typography variant="h2">
                  CenturyLink Business Internet and services are in all these{' '}
                  {State_Name} cities
                </Typography>
                <Typography>{City_Dropdown_Copy}</Typography>
                <Dropdown
                  options={cityDropdownData}
                  onChange={(e) => navigate(e.value)}
                  placeholderText="Select City"
                />
                {cityDropdownData?.map((current) => (
                  <StyledLink href={current.value} key={current.value}>
                    {current.label}
                  </StyledLink>
                ))}
                <Brandy text={City_Dropdown_Disclaimer} />
              </>
            }
            alignMainContent="center"
          />
          <VariableContent
            mainContent={<Typography variant="h2">FAQ</Typography>}
            alignMainContent="center"
          >
            <Stack spacing="xxl" alignMainContent="center">
              <Accordion
                items={[
                  {
                    title: getFAQ(FAQ_1).question,
                    content: getFAQ(FAQ_1).answer,
                  },
                  {
                    title: getFAQ(FAQ_2).question,
                    content: getFAQ(FAQ_2).answer,
                  },
                  {
                    title: getFAQ(FAQ_3).question,
                    content: getFAQ(FAQ_3).answer,
                  },
                  {
                    title: getFAQ(FAQ_4).question,
                    content: getFAQ(FAQ_4).answer,
                  },
                  {
                    title: getFAQ('ALL1').question,
                    content: getFAQ('ALL1').answer,
                  },
                  {
                    title: getFAQ('ALL2').question,
                    content: getFAQ('ALL2').answer,
                  },
                ]}
              />
            </Stack>
          </VariableContent>
        </>
      }
      // footer={<Footer disclaimers={[data?.footerDisclaimerOne]} />}
    />
  )
}

StateTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default StateTemplate

const StyledLink = styled.a`
  display: none !important;
`

export const query = graphql`
  query StateTemplateQuery(
    $stateFlag: String
    $stateHero: String
    $pagePath: String
    $stateAbbreviation: String
  ) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerBusinessCenturylinkAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          Page_Path
          Static_Number
          Promo_Code
          State
          Hero_Copy
          Hero_Disclaimer
          Cards_Copy
          Zip_Code_Disclaimer
          Package_Cards_Copy
          City_Dropdown_Copy
          City_Dropdown_Disclaimer
          FAQ_1
          FAQ_2
          FAQ_3
          FAQ_4
        }
      }
    }
    cities: allDatasetManagerBusinessCenturylinkAoa(
      filter: { State: { eq: $stateAbbreviation }, Area_Type: { eq: "city" } }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    stateHero: contentfulMedia(identifier: { eq: $stateHero }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    stateFlag: contentfulMedia(identifier: { eq: $stateFlag }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    cardsOne: contentfulMedia(contentful_id: { eq: "6x5XOvyoSG0KfxlSHphbId" }) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    cardsTwo: contentfulMedia(contentful_id: { eq: "4qBV80rKs3NdpqKZB3Tm4i" }) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    allContentfulPackage {
      edges {
        node {
          __typename # used to determine component to render
          id
          contentful_id # required to make association
          identifier # user created contentful identifier
          brandy {
            name
            details
            bullets {
              text
              disclaimer {
                text
                symbol
              }
            }
            price {
              currency
              sale
              amount
              duration
              prefix
              suffix
              before
              disclaimer {
                text
                symbol
              }
            }
          }
          label
        }
      }
    }
    footerDisclaimerOne: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ctlb-copyright" }
    ) {
      symbol
      text
    }
    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "e7443d9c-d711-5d2e-b2a9-3c19bb6a80fc" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "ac96fb7d-5573-53ee-b0ac-3a36f111e74d" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
  }
`
